import React from "react";
import "../css/sliderBrand.css";
import yanmar1 from "../img/yanmar1.svg";
import cummins2 from "../img/cummins2.webp";
import volvo3 from "../img/volvo3.webp";
import mtu4 from "../img/mtu4.webp";
import johnD5 from "../img/johnD5.webp";
import phasor6 from "../img/phasor6.webp";
import maspower7 from "../img/maspower7.webp";
import balmar8 from "../img/balmar8.webp";
import aquadrive9 from "../img/aquadrive9.webp";

const SliderBrands = () => {
  return (
    <div className="logos">
      <div className="logos-slide">
        <img src={yanmar1} alt={"Slide 1"} />
        <img src={cummins2} alt="Slide 2" />
        <img src={volvo3} alt="Slide 3" />
        <img src={mtu4} alt="Slide 4" />
        <img src={johnD5} alt="Slide 5" />
        <img src={phasor6} alt="Slide 6" />
        <img src={maspower7} alt="Slide 7" />
        <img src={balmar8} alt="Slide 8" />
        <img src={aquadrive9} alt="Slide 9" />
      </div>
      <div className="logos-slide">
        <img src={yanmar1} alt={"Slide 1"} />
        <img src={cummins2} alt="Slide 2" />
        <img src={volvo3} alt="Slide 3" />
        <img src={mtu4} alt="Slide 4" />
        <img src={johnD5} alt="Slide 5" />
        <img src={phasor6} alt="Slide 6" />
        <img src={maspower7} alt="Slide 7" />
        <img src={balmar8} alt="Slide 8" />
        <img src={aquadrive9} alt="Slide 9" />
      </div>
    </div>
  );
};

export default SliderBrands;
