import React from "react";
import "../css/services.css";
import { Icon } from "@iconify/react";
import service1 from "../img/service1.webp";
import service2 from "../img/service2.webp";
import service3 from "../img/service3.webp";
import service4 from "../img/service4.webp";
import service5 from "../img/service5.webp";
import service6 from "../img/service6.webp";
import navegacion from "../img/navegacion.webp";
import autocad from "../img/autocad.webp";

export default function Services() {
  return (
    <section className="services">
      <h1>
        Our <span>services</span>
      </h1>
      <p id="text-services">
        We offer a wide range of services designed to keep your boat in optimal
        condition at all times.
      </p>
      <div className="service-white">
        <div>
          <h2>Diesel Engine</h2>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>
              Complete Yanmar & Cummins electronic services tools and software
            </p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Service and maintenance</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Trouble Shooting and / or new Layout</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Exhaust Systems</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Raw Water System</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Air Intake System</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Installation</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Mounting System</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Fuel System</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>
              Small repairs up to complete overhauls, for majority of diesel
              engines
            </p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Electrical System</p>
          </div>
        </div>
        <img src={service1} alt="Service-photo" />
      </div>
      <div className="service-blue">
        <img src={service2} alt="Service-photo" />
        <div>
          <h2>Transmission</h2>
          <div className="service2">
            <div>
              <div className="item-service">
                <Icon
                  icon="fluent:shield-task-24-filled"
                  id="icon-security-blue"
                />
                <p>Selection</p>
              </div>
              <div className="item-service">
                <Icon
                  icon="fluent:shield-task-24-filled"
                  id="icon-security-blue"
                />
                <p>Cooling</p>
              </div>
            </div>
            <div className="part2-service2">
              <div className="item-service">
                <Icon
                  icon="fluent:shield-task-24-filled"
                  id="icon-security-blue"
                />
                <p>Resilient Mounting</p>
              </div>
              <div className="item-service">
                <Icon
                  icon="fluent:shield-task-24-filled"
                  id="icon-security-blue"
                />
                <p>Torsional Coupling</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-white">
        <div>
          <h2>Repower</h2>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Redesign of engines bed structure aluminun or fiberglass</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Installation and sales on engine and Transmissione</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Design, sales and installation on: </p>
          </div>
          <div className="repower-note">
            <img src={navegacion} alt="icon-boat" />
            <p>Exhaust system, fuel system, mounting and raw water system</p>
          </div>
        </div>
        <img src={service3} alt="Service-photo" />
      </div>
      <div className="service-blue">
        <img src={service4} alt="Service-photo" />
        <div>
          <h2>Balmar Alternator System</h2>

          <div className="item-service">
            <Icon icon="fluent:shield-task-24-filled" id="icon-security-blue" />
            <p>12V, 24V & 48V Systems</p>
          </div>
          <div className="item-service">
            <Icon icon="fluent:shield-task-24-filled" id="icon-security-blue" />
            <p>Update Yanmar Valeo Alternator for External regulator</p>
          </div>
          <div className="item-service">
            <Icon icon="fluent:shield-task-24-filled" id="icon-security-blue" />
            <p>Lithium and AGM Battery system services and maintenance</p>
          </div>
        </div>
      </div>
      <div className="service-white">
        <div>
          <h2>AutoCAD</h2>
          <div className="service2">
            <div>
              <div className="item-service">
                <Icon
                  icon="fluent:shield-task-24-filled"
                  id="icon-security-white"
                />
                <p>Selection</p>
              </div>
              <div className="item-service">
                <Icon
                  icon="fluent:shield-task-24-filled"
                  id="icon-security-white"
                />
                <p>Cooling</p>
              </div>
            </div>
            <div className="part2-service2">
              <div className="item-service">
                <Icon
                  icon="fluent:shield-task-24-filled"
                  id="icon-security-white"
                />
                <p>Resilient Mounting</p>
              </div>
              <div className="item-service">
                <Icon
                  icon="fluent:shield-task-24-filled"
                  id="icon-security-white"
                />
                <p>Torsional Coupling</p>
              </div>
            </div>
          </div>
        </div>
        <img src={autocad} alt="Service-photo" id="img-small" />
      </div>
      <div className="service-blue">
        <img src={service5} alt="Service-photo" />
        <div>
          <h2>Propeller – Propeller Shaft </h2>

          <div className="item-service">
            <Icon icon="fluent:shield-task-24-filled" id="icon-security-blue" />
            <p>Calculation</p>
          </div>
          <div className="item-service">
            <Icon icon="fluent:shield-task-24-filled" id="icon-security-blue" />
            <p>Selection and sales</p>
          </div>
        </div>
      </div>
      <div className="service-white">
        <div>
          <h2>Victron</h2>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Charging systems</p>
          </div>
          <div className="item-service">
            <Icon
              icon="fluent:shield-task-24-filled"
              id="icon-security-white"
            />
            <p>Sales and installation</p>
          </div>
        </div>
        <img src={service6} alt="Service-photo" />
      </div>
    </section>
  );
}
