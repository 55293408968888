import React from "react";
import "../css/work.css";
import photo1_1 from "../img/photo1_1.webp";
import photo1_2 from "../img/photo1_2.webp";
import photo1 from "../img/photo1.webp";
import photo2 from "../img/photo2.webp";
import photo3 from "../img/photo3.webp";
import photo4 from "../img/photo4.webp";
import photo4_1 from "../img/photo4_1.webp";
import photo5 from "../img/photo5.webp";
import photo6 from "../img/photo6.webp";
import photo7 from "../img/photo7.webp";
import linea from "../img/linea.webp";
import planet from "../img/planet.webp";

export default function Work() {
  return (
    <section className="work">
      <h2>Our work</h2>
      <h1>We make your trip safe around the world</h1>
      <img className="planeta" src={planet} alt="planet" />
      <div className="principal-work">
        <div className="section-left">
          <div>
            <img id="img-1" src={photo1_1} alt="img-work" />
            <div className="izq-1">
              <h3>Panama</h3>
              {/* <p>September - 2023</p> */}
            </div>
          </div>
          <div>
            <img src={photo1} alt="img-work" />
            <div className="izq-2">
              <h3>Fort Lauderdale International Boat show</h3>
              {/* <p>October - 2022</p> */}
            </div>
          </div>
          <div>
            <img src={photo3} alt="img-work" />
            <div className="izq-3">
              <h3>Alaska</h3>
              {/* <p>May - 2022</p> */}
            </div>
          </div>
          <div>
            <img src={photo4_1} alt="img-work" />
            <div className="izq-4">
              <h3>La Romana - Dom Rep</h3>
              {/* <p>April - 2022</p> */}
            </div>
          </div>
          <div>
            <img src={photo6} alt="img-work" />
            <div className="izq-5">
              <h3>Panama</h3>
              {/* <p>October - 2019</p> */}
            </div>
          </div>
        </div>
        <img className="img-line" src={linea} alt="linea" />
        <div className="section-right">
          <div>
            <div id="top-right">
              <h3>Panama</h3>
              {/* <p>October - 2023</p> */}
            </div>
            <img className="dere1" src={photo1_2} alt="img-work" />
          </div>
          <div className="space-right">
            <div className="dere2">
              <h3>Alaska</h3>
              {/* <p>June - 2023</p> */}
            </div>
            <img src={photo2} alt="img-work" />
          </div>
          <div>
            <div className="dere3">
              <h3>Cancún - Cozumel</h3>
              {/* <p>September - 2022</p> */}
            </div>
            <img src={photo4} alt="img-work" />
          </div>
          <div>
            <div className="dere4">
              <h3>Panama</h3>
              {/* <p>May - 2022</p> */}
            </div>
            <img src={photo5} alt="img-work" />
          </div>
          <div>
            <div className="dere5">
              <h3>The island of antigua</h3>
              {/* <p>December - 2019</p> */}
            </div>
            <img src={photo7} alt="img-work" />
          </div>
        </div>
      </div>
    </section>
  );
}
